import React, { useContext, useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Axios from "axios"
import { LangStateContext } from "@/context/langContext"
import { RegionContext } from "@/context/regionContext"
import MdWrapper from "@/components/MarkdownWrap"

function Index(props) {
  const selectedLang = useContext(LangStateContext)
  const region = useContext(RegionContext)
  const [markdown, setMarkdown] = useState(null)

  const getMdText = async (lang, country) => {
   let mdURL=`${process.env.resourceUrl}docs/shop/price-notice.md`
    Axios.get(mdURL).then(function (data) {
      setMarkdown(data.data)
    })
  }

  useEffect(() => {
    getMdText(selectedLang, region.toUpperCase())
  }, [selectedLang, region])

  return (
    <div style={{height:'100vh'}}>
   
      {/* <div style={{ paddingTop: 100 }}></div> */}
      {markdown != null ? (
        <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
      ) : (
        ""
      )}
    </div>
  )
}

export default Index
